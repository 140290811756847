@import '../../node_modules/bootstrap/scss/functions';
@import '../../bootstrap/variables.scss';
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

.content {
	h2,
	h3,
	h4,
	h5 {
		margin-top: 2.5rem;

		&:first-child {
			margin-top: 0;
		}
	}

	ul li {
		margin-bottom: .25rem;
	}

	img {
		margin-bottom: 2.5rem;
	}

	table {
		--#{$prefix}table-striped-color: #{$table-striped-color};
  		--#{$prefix}table-striped-bg: #{$table-striped-bg};
		width: 100%;
		margin-bottom: 1.5 * $spacer;
		vertical-align: $table-cell-vertical-align;
		border-color: var(--#{$prefix}table-border-color);

		> :not(caption) > * > * {
			padding: $table-cell-padding-y $table-cell-padding-x;
			background-color: var(--#{$prefix}table-bg);
			border-bottom-width: 0;
			box-shadow: inset 0 0 0 9999px var(--#{$prefix}table-accent-bg);
		}

		> :not(:first-child) {
			border-top-width: 0;
		}

		> tbody {
			vertical-align: inherit;
		}

		> thead {
			vertical-align: bottom;
		}

		> tbody > tr:nth-of-type(#{$table-striped-order}) > * {
			--#{$prefix}table-accent-bg: var(--#{$prefix}table-striped-bg);
			color: var(--#{$prefix}table-striped-color);
		}
	}
}
