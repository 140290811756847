@import '../../node_modules/bootstrap/scss/functions';
@import "../../node_modules/bootstrap/scss/_variables";
@import '../../bootstrap/variables.scss';
@import "../../node_modules/bootstrap/scss/mixins";

.account-tile {
	width: 50%;
	height:170px;

	&.bg-0 {
		background: url('./bg0.png');
	}
	&.bg-1 {
		background: url('./bg1.png');
	}
	&.bg-2 {
		background: url('./bg2.png');
	}

	.tile-icon {
		position: absolute;
		top: 17px;
		left: 17px;

		svg {
			height: 45px;

			@include media-breakpoint-up(md) {
				height: initial;
			}
		}

	}

	.tile-counter {
		font-size: $font-size-base * 2;
		font-family: $headings-font-family;
		line-height: $headings-line-height;
		font-weight: bold;
		color: $blue;
		margin-top: map-get($spacers, 7);

		@include media-breakpoint-up(md) {
			font-size: $h1-font-size;
			margin-top: map-get($spacers, 6);
		}
	}

	.tile-title {
		font-size: $h3-font-size;
		font-family: $headings-font-family;
		line-height: $headings-line-height;
		font-weight: bold;
		color: $blue;
		margin-top: map-get($spacers, 2);

		@include media-breakpoint-up(md) {
			font-size: $h2-font-size;
		}
	}

	.text-white {
		&.tile-title, &.tile-counter {
			color: $white;
		}
	}

	.text-orange {
		&.tile-title, &.tile-counter {
			color: $orange;
		}
	}


	@include media-breakpoint-up(md) {
		width: 255px;
		height:208px;
	}
}
