// Bootstrap default variable overrides

// --- Options
$enable-rounded:			false;
$enable-rfs:				true;
$enable-validation-icons:	false;
$enable-negative-margins:	true;

// --- Spacing
$spacer: 1rem;
$spacers: (
	0: 0,
	1: $spacer * .25,
	2: $spacer * .5,
	3: $spacer,
	4: $spacer * 1.5,
	5: $spacer * 2,
	6: $spacer * 2.5,
	7: $spacer * 3,
	8: $spacer * 5
);

// --- Color system
$white:						#fff;
$orange:					#FF8B2C;
$blue:						#003262;
$blue-dark:					#001F38;
$grey:						#7E8697;
$grey-light:				#F2F6F8;

$primary:					$orange;
$secondary:					$blue;
$light:						$grey-light;
$dark:						$blue-dark;
$info:						$grey;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "info":       $info,
  "light":      $light,
  "dark":       $dark
);

$color-contrast-dark:		$blue;

$body-bg: 					$grey-light;
$body-color:				$grey;

// Create your own map
$custom-colors: (
	"blue-dark": $blue-dark,
	"grey-light": $grey-light
)!default;

// --- Links

// --- Images
$aspect-ratios: (
	"1x1": 100%,
	"4x3": calc(3 / 4 * 100%),
	"3x4": calc(4 / 3 * 100%),
	"16x9": calc(9 / 16 * 100%),
	"21x9": calc(9 / 21 * 100%)
);

// --- Grid
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px
);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1320px
);

$grid-columns:					12;
$grid-gutter-width:				1.875rem;

// --- Header
$header-sm-height: 50px;
$header-xl-height: 92px;

// --- Typography
$font-family-base:				'Roboto', sans-serif;
$font-weight-normal:			500;
$font-size-base:				1rem;

$paragraph-margin-bottom:		1.5rem;

$h1-font-size:					$font-size-base * 3;
$h2-font-size:					$font-size-base * 1.5; // together with .text-uppercase
$h3-font-size:					$font-size-base * 1.25;
$h4-font-size:					$font-size-base * 1.125;
$h5-font-size:					$font-size-base;
$h6-font-size:					$font-size-base;

$headings-margin-bottom:		$spacer * 1.5;
$headings-font-family:			'Lora', serif;
$headings-font-weight:			400;
$headings-line-height:			1.25;
$headings-color:				$blue;

$display-font-sizes: (
	1: 3rem,
	2: 2.5rem,
	3: 1.5rem,
	4: 1.25rem,
	5: 1.125rem,
	6: 1
) !default;

$display-font-weight:			700;
$display-font-family:			'Lora', serif;

// --- Components
$component-active-bg:			$grey-light;
$component-active-color:		$blue;

// --- Buttons + Forms
$form-label-color:				$secondary;
$form-feedback-invalid-color:	$primary;

// buttons
$input-btn-padding-y:			.625rem;
$input-btn-padding-x:			1.35rem;
$input-btn-padding-x-lg:		4rem;
$input-btn-border-width:		2px;
$input-btn-padding-y-sm:		.5rem;
$input-btn-padding-x-sm:		1.125rem;
$input-btn-focus-box-shadow:	null;
$input-btn-focus-width:			.15rem;

$btn-padding-y:					$input-btn-padding-y;
$btn-padding-x:					2rem;
$btn-padding-y-lg:				$input-btn-padding-y;
$btn-padding-x-lg:				$input-btn-padding-x-lg;
$btn-font-size-lg:				$font-size-base;

$btn-white-space:				nowrap;

$btn-box-shadow:				null;
$btn-focus-width:				null;
$btn-focus-box-shadow:			null;
$btn-active-box-shadow:			null;

$btn-hover-bg-shade-amount:				35%;
$btn-hover-bg-tint-amount:				50%;
$btn-hover-border-shade-amount:			25%;
$btn-hover-border-tint-amount:			0%;
$btn-active-bg-shade-amount:			35%;
$btn-active-bg-tint-amount:				50%;
$btn-active-border-shade-amount:		25%;
$btn-active-border-tint-amount:			0%;

// form text
$input-focus-box-shadow:				0 0 0 tint-color($grey, 70%);
$input-focus-border-color:				$grey-light;
$input-border-color:					$grey-light;
$input-box-shadow:						none;
$input-padding-x:						$input-btn-padding-x;
$input-color:							$blue;
$input-disabled-bg:						$grey-light;

$input-padding-y-sm:					.375rem;
$input-font-size-sm:					1rem;

// checkbox
$form-check-input-border:				3px solid $blue;
$form-check-input-width:				1.2em;
$form-check-input-focus-box-shadow: 	none;
$form-check-input-checked-border-color:	$blue;
$form-check-input-focus-border:			$blue;
$form-check-label-color:				$secondary;

// select
$form-select-padding-x:					$input-padding-x;
$form-select-indicator-color:			$blue;
$form-select-indicator:					url('data:image/svg+xml,<svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.142 1.657 7.485 7.314 1.828 1.657" stroke="#{$form-select-indicator-color}" stroke-width="3"/></svg>');
$form-select-indicator-padding:			$form-select-padding-x * 1.865;
$form-select-bg-position:				right $form-select-padding-x *.5 center;
$form-select-focus-box-shadow:			0 0 0 .25rem rgba($grey, .25);

//range
$form-range-track-bg:					transparent;
$form-range-thumb-border:				3px solid $primary;
$form-range-thumb-bg:					$white;
$form-range-thumb-active-bg:			$primary;
$form-range-thumb-box-shadow:			none;
$form-range-thumb-focus-box-shadow:		none;

// --- Navs & tabs
$nav-link-padding-y:					.75rem;
$nav-link-padding-x:					.5rem;

// --- Navbar
$navbar-padding-y:					0;
$navbar-padding-x:					0;

$navbar-toggler-padding-y:			.563rem;
$navbar-toggler-padding-x:			.75rem;
$navbar-nav-link-padding-x:			.5rem;
$navbar-toggler-focus-width:		.05rem;

$navbar-dark-color:					rgba($white, 1);
$navbar-dark-hover-color:			rgba($white, 1);
$navbar-dark-active-color:			$white;
$navbar-dark-disabled-color:		rgba($white, .25);

// --- Dropdowns
$dropdown-padding-y:				0;
$dropdown-link-color:				$body-color;
$dropdown-link-hover-color:			$body-color;
$dropdown-link-hover-bg:			$grey-light;
$dropdown-item-padding-y:			$spacer * .563;
$dropdown-item-padding-x:			$spacer;


// ---- Cards
$card-spacer-y:						$spacer * 1.5;
$card-spacer-x:						$spacer * 2;
$card-title-spacer-y:				$spacer * .8;
$card-border-width:					0;
$card-border-radius:				null;
$card-box-shadow:					null;
$card-inner-border-radius:			null;
$card-cap-padding-y:				$spacer * .5;
$card-cap-padding-x:				$card-spacer-x;
$card-cap-bg:						$blue;
$card-cap-color:					$white;
$card-height:						null;
$card-color:						$grey;
$card-bg:							$white;
$card-img-overlay-padding:			$spacer * 2;
$card-group-margin:					$grid-gutter-width * .5;


// --- Breadcrumbs
$breadcrumb-divider: url('data:image/svg+xml,%3Csvg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M.536 1 4.07 4.536.536 8.07" stroke="#7E8697"/%3E%3C/svg%3E');
$breadcrumb-divider-color:			$grey;
$breadcrumb-active-color:			$white;
$breadcrumb-font-size:				.875rem;

// --- Modal
$modal-backdrop-opacity:			.8;
$modal-inner-padding:				$spacer * 2.5;

// --- Close button
$btn-close-width:					1rem;
$btn-close-padding-x:				.53em;
$btn-close-opacity:					1;
$btn-close-hover-opacity:			.75;
$btn-close-color:					$white;
$btn-close-bg:						url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.536 11.893c.25.25.375.553.375.91 0 .358-.125.661-.375.911l-1.822 1.822a1.24 1.24 0 0 1-.91.375 1.24 1.24 0 0 1-.911-.375l-3.938-3.938-3.937 3.938a1.24 1.24 0 0 1-.91.375 1.24 1.24 0 0 1-.912-.375L.375 13.714a1.24 1.24 0 0 1-.375-.91c0-.358.125-.661.375-.911l3.938-3.938L.374 4.018A1.24 1.24 0 0 1 0 3.108c0-.358.125-.662.375-.912L2.196.375C2.446.125 2.75 0 3.107 0s.66.125.91.375l3.938 3.938L11.893.374c.25-.25.553-.375.91-.375.358 0 .661.125.911.375l1.822 1.821c.25.25.375.554.375.911s-.125.66-.375.91l-3.938 3.938 3.938 3.938Z" fill="#{$btn-close-color}"/></svg>');

// --- Accordion
$accordion-color:					$secondary;
$accordion-padding-y:				1rem;
$accordion-padding-x:				2rem;
$accordion-border-width:			0;
$accordion-button-color:			$secondary;
$accordion-button-active-bg:		$secondary;
$accordion-button-active-color:		$white;
$accordion-icon-color:				$accordion-button-color;
$accordion-icon-active-color:		$accordion-button-active-color;
$accordion-button-icon:				url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#{$accordion-icon-color}" d="M6.668 0h2.667v16H6.668z"/><path fill="#{$accordion-icon-color}" d="M0 9.333V6.666h16v2.667z"/></svg>');
$accordion-button-active-icon:		url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#{$accordion-icon-active-color}" d="M0 9.333V6.666h16v2.667z"/></svg>');

// --- Tooltip
$tooltip-bg:						$dark;
