@import '../../node_modules/bootstrap/scss/functions';
@import '../../bootstrap/variables.scss';
@import "../../node_modules/bootstrap/scss/mixins";

.comparison-panel {
	z-index: 10;
	max-height: 90vh;
	overflow-y: auto;

	.btn {
		--bs-btn-focus-box-shadow: none;
	}

	.btn svg {
		margin-left: 6px;
	}

	.btn[aria-expanded="false"] svg {
		transform: rotateX(180deg);
	}

	&-overlay {
		overflow: hidden;
		height: 100%;
		width: 100%;
		position: relative;
		min-height: 113px;
	}

	&-overlay img {
		height: 100%;
		max-width: none;
		min-width: 100%;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);

		@include media-breakpoint-up(xl) {
			width: 100%;
			height: auto;
		}
	}
}
