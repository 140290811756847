@import "../node_modules/bootstrap/scss/functions";
@import "../bootstrap/variables.scss";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

img.w-auto {
	@include media-breakpoint-up(lg) {
		max-width: none;
	}
}

.w-50 {
	width: 100%!important;

	@include media-breakpoint-up(sm) {
		width: 50%!important;
	}
}

.img-overlay {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&::before {
		display: block;
		padding-top: var(--#{$prefix}aspect-ratio);
		content: "";
	}

	> img {
		position: absolute;
		top: 0;
		left: 50%;
		height: 100%;
		width: auto;
		min-width: 100%;
		max-width: none;
		transform: translateX(-50%);
	}
}

.card-img-top {
	aspect-ratio: 350 / 200;
	object-fit: cover;
}
