@import "../node_modules/bootstrap/scss/functions";
@import "../bootstrap/variables.scss";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

// Headings
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	a {
		text-decoration: none;
		color: inherit;
	}
}

h2,
.h2,
h5,
.h5 .display-2,
.display-5 {
	text-transform: uppercase;
}

h3,
.h3,
h4,
.h4 {
	font-weight: 700;
	text-transform: inherit;
}

.subtitle {
	color: $headings-color;
	margin-bottom: .75rem;
}

.subtitle-alt {
	font-weight: $font-weight-bold;
	color: $white;
	font-family: $display-font-family;
	font-size: $font-size-base * 1.25;
}

.property-list-footer {
	font-weight: $font-weight-bold;
	color: $blue;
	font-family: $display-font-family;
	font-size: $font-size-base * 1.5;
}

.text-white {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6,
	.subtitle {
		color: $white;
	}
}

.text-orange {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6,
	.subtitle {
		color: $orange;
	}
}

// Links {
a {
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

a.btn {
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}
}

// Images

img {
	max-width: 100%;
}
