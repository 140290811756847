@import '../../node_modules/bootstrap/scss/functions';
@import '../../bootstrap/variables.scss';
@import "../../node_modules/bootstrap/scss/variables";

.testimonial {
	position: relative;
	background: $grey-light;
	z-index: 2;
	overflow: hidden;
	padding-bottom: 2.5rem;



	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 200%;
		height: 130px;
		display: block;
		background-color: $secondary;
		transform: rotate(-5deg) translate(-25%, -35%);
		z-index: -1;
	}

	.col-lg-4:nth-child(even) & {
		&::before {
			transform: rotate(5deg) translate(-25%, -12%) ;
		}
	}

	&-img {
		width: 160px;
		height: 160px;
		margin: 1rem auto 2rem;
		position: relative;

		& > img {
			border-radius: 50%;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&::after {
			content: "";
			position: absolute;
			top: -20px;
			width: 64px;
			height: 64px;
			border-radius: 50%;
			display: block;
			background-color: $primary;
			background-repeat: no-repeat;
			background-position: center center;
			background-image: escape-svg(url('data:image/svg+xml,<svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.646 7.414c-1.316 2.44-2.298 4.814-2.934 7.125-.642 2.311-.963 4.494-.963 6.548 0 2.888.739 5.315 2.215 7.273 1.476 1.957 3.69 2.907 6.644 2.837 2.118 0 3.806-.72 5.058-2.17 1.252-1.444 1.88-3.132 1.88-5.058 0-2.183-.673-3.787-2.021-4.815-1.348-1.026-2.889-1.54-4.622-1.54-.321 0-.623.02-.912.051a6.94 6.94 0 0 0-1.104.244c-.064-1.861.225-3.8.867-5.822.642-2.022 1.393-3.864 2.266-5.533.867-1.67 1.65-2.953 2.356-3.852L28.608 0c-1.99 2.503-3.646 4.975-4.956 7.414h-.006Zm-19.251 0c-1.316 2.44-2.298 4.814-2.934 7.125C.82 16.85.5 19.033.5 21.087c0 2.888.738 5.315 2.214 7.273 1.477 1.957 3.691 2.907 6.644 2.837 2.118 0 3.8-.72 5.058-2.17 1.252-1.444 1.875-3.132 1.875-5.058 0-2.183-.674-3.787-2.022-4.815-1.349-1.026-2.89-1.54-4.622-1.54-.321 0-.63.02-.918.051a7.15 7.15 0 0 0-1.11.244c-.065-1.861.224-3.8.866-5.822a35.518 35.518 0 0 1 2.26-5.533c.866-1.67 1.65-2.953 2.362-3.852L9.35 0C7.36 2.503 5.704 4.975 4.395 7.414Z" fill="#fff"/></svg>'));
		}
	}

	&-stars {
		width: 213px;
		height: 33px;
		margin: auto;
	}
}
