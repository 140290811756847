@import '../../node_modules/bootstrap/scss/functions';
@import '../../bootstrap/variables.scss';
@import "../../node_modules/bootstrap/scss/mixins";

.loan-sumulator {
	&-bg {
		background: url('./loan-simulator-bg.jpg') no-repeat 50% 50%;
		background-size: cover;
		width: 100%;
		height: 100%;
	}

	&-result {
		--rent: 360deg;
		--loan: 360deg;

		background:
			conic-gradient($white 0 var(--rent), $grey-light var(--rent) var(--loan), $white var(--loan) 360deg) padding-box,
			conic-gradient($secondary 0 var(--rent), $primary var(--rent) var(--loan), $grey var(--loan) 360deg) border-box;
		border-radius: 50em;
		border: 20px solid transparent;
		width: 200px;
		height: 200px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		font-weight: bold;
		transition: background 0.2s ease-out;
	}

	&-result-currency {
		font-size: 40px;
		line-height: 1;
		font-family: $headings-font-family;
	}

	&-legend {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		margin: 0;

		li {
			margin-top: map_get($spacers, 3);

			&:first-child {
				margin-top: 0;
			}
		}

		li:before {
			content: '';
			display: inline-block;
			width: 16px;
			height: 16px;
			margin-right: 16px;
			margin-bottom: -2px;
		}

		li.text-primary:before {
			background-color: $primary;
		}

		li.text-secondary:before {
			background-color: $secondary;
		}

		li.text-info:before {
			background-color: $info;
		}
	}
}
