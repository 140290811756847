@import '../../node_modules/bootstrap/scss/functions';
@import '../../bootstrap/variables.scss';
@import "../../node_modules/bootstrap/scss/mixins";

.media-gallery {

	&-item {

	}

	&-item  .img-fluid {
		max-height: 50vh;

		@include media-breakpoint-up(xl) {
			max-height: 100%;
		}
	}

	&-controls-prev,
	&-controls-next {
		position: absolute;
		top: 35%;
		padding: 0;
		width: 3rem;
		height: 3rem;
		text-align: center;
		vertical-align: middle;

		@include media-breakpoint-up(xl) {
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&-controls-prev {
		left: 0;

		@include media-breakpoint-up(xl) {
			transform: translateX(-50%);
		}
	}

	&-controls-next {
		right: 0;

		@include media-breakpoint-up(xl) {
			transform: translateX(50%);
		}
	}

	&-thumbnails {
		img {
			opacity: .5;
		}

		.tns-nav-active img {
			opacity: 1;
		}
	}
}
