@import '../../node_modules/bootstrap/scss/functions';
@import '../../bootstrap/variables.scss';
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .form-select:invalid,
.form-select.is-invalid {
	border-color: $grey-light;

	&:focus {
		box-shadow: none;
		border-color: $grey-light;
	}
}

input[type="number"] {
	appearance: textfield;

	&::-webkit-inner-spin-button {
		display: none;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		appearance: none;
		margin: 0;
	}
}
