@import '../../node_modules/bootstrap/scss/functions';
@import '../../bootstrap/variables.scss';
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

// Background image for sections

.bg-primary-img {
	background: $secondary url('./bg-orange.png') no-repeat 100% 100%;
	background-size: auto 100%;

	@include media-breakpoint-up(lg) {
		background-position: 300px 100%;
	}

	@include media-breakpoint-up(xl) {
		background-position: 100% 100%;
	}
}

.bg-secondary-img {
	background: $secondary url('./bg-blue.jpg') no-repeat 100% 100%;
	background-size: contain;
}

.bg-secondary-img-lg {
	background: $secondary url('./bg-blue-lg.jpg') no-repeat 50% 100%;
	background-size: auto 100%;

	@include media-breakpoint-up(lg) {
		background-position: 100% 100%;
	}
}

.bg-secondary-img-waves {
	background: $secondary url('./bg-blue-waves.jpg') no-repeat 100% 100%;
	background-size: cover;
}

.bg-img-roof {
	background: url('./bg-blue-roof.jpg') no-repeat 100% 0%;
	background-size: auto;

	@include media-breakpoint-up(lg) {
		background-size: contain;
	}
}

.bg-secondary-d {
	background: linear-gradient(177deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 100px, $secondary 101px, $secondary 100%);

	// to hide if not used on page.html template
	&:empty,
	> div:empty {
		display: none;
	}
}

.bg-secondary-dm {
	background: linear-gradient(172deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 45%, $secondary calc(45% + 1px), $secondary 100%);
}

.bg-secondary-d-lr {
	background: linear-gradient(184deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 135px, $secondary 136px, $secondary 100%);
}

.bg-secondary-dark {
	background-color: $secondary;
	background: linear-gradient(68deg, $dark 0, $dark 38%, $secondary calc(38% + 1px), $secondary 100%);
}

.bg-white-img {
	background: $white url('./bg-white.jpg') no-repeat 100% 100%;
	background-size: cover;
}

.bg-white-img-left {
	background: $white url('./bg-white-left.jpg') no-repeat 0% 100%;
	background-size: contain;
}
.bg-white-img-right {
	background: $white url('./bg-white-right.jpg') no-repeat 100% 100%;
	background-size: auto 80%;
}

// Footer
.bg-dark-img {
	position: relative;
	z-index: 900;
	background: $dark;

	&:before {
		content: '';
		background: url('./bg-footer.png') no-repeat 0% 0%;
		background-size: 100% 100%;
		width: 100%;
		height: 50px;
		position: absolute;
		bottom: calc(100% - 1px);
		left: 0;
		z-index: 950;
	}
}
