@import '../../node_modules/bootstrap/scss/functions';
@import '../../bootstrap/variables.scss';
@import "../../node_modules/bootstrap/scss/mixins";

.property-description {
	max-height: 1000px;
	overflow: hidden;
	transition: max-height 1s ease-out;

	&-truncate {
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		display: -webkit-box;
		max-height: 120px;
	}
}
