@import '../../node_modules/bootstrap/scss/functions';
@import '../../bootstrap/variables.scss';
@import "../../node_modules/bootstrap/scss/mixins";

.share-group {
	position: relative;
	z-index: 1;

	&-panel {
		display: none;
		flex-direction: column;
		position: absolute;
		bottom: 100%;
		margin-bottom: 15px;

		&:after {
			content: '';
			display: inline-block;
			border-top: 8px solid;
			border-top-color: inherit;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
		}
	}
}
