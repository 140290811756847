@import '../../node_modules/bootstrap/scss/functions';
@import '../../bootstrap/variables.scss';
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

.form-range {
	--range-bg-pos: 0% 100%;

	&::-webkit-slider-runnable-track {
		background-color: $grey-light;
		background-size: var(--range-bg-pos);
		background-image: linear-gradient($primary, $primary);
		background-repeat: no-repeat;
		border-radius: .5rem;
	}

	&::-moz-range-track {
		background-color: $grey-light;
		background-size: var(--range-bg-pos);
		background-image: linear-gradient($primary, $primary);
		background-repeat: no-repeat;
		border-radius: .5rem;
	}

	&::-webkit-slider-thumb {
		border-radius: 50%;
	}

	&::-moz-range-thumb {
		border-radius: 50%;
	}

	&-output {
		position: absolute;
		top: 100%;
		left: 0%;
		transform: translateX(-50%);
		white-space: nowrap;
	}
}
