@import '../../bootstrap/variables.scss';

.btn-back-top {
	width: 50px;
	height: 50px;
	padding: 0;
	margin-right: calc($grid-gutter-width / 2);
	position: absolute;
	top: -30px;
	right: 0;
	z-index: 1000;
}
