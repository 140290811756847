@import '../../node_modules/bootstrap/scss/functions';
@import '../../bootstrap/variables.scss';
@import "../../node_modules/bootstrap/scss/mixins";

.property-slider {
	position: relative;

	&-controls {
		.btn {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			padding: .5rem 1rem;
		}

		.btn-prev {
			left: 0;
		}

		.btn-next {
			right: 0;
		}
	}
}
