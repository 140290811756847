@import '../../node_modules/bootstrap/scss/functions';
@import '../../bootstrap/variables.scss';
@import "../../node_modules/bootstrap/scss/mixins";

.comparison-table {
	--bs-table-striped-bg: var(--bs-light);

	thead th {
		height: 1rem;
	}

	th {
		min-width: 150px;
		color: $secondary!important;

		&:first-child {
			display: none;

			@include media-breakpoint-up(md) {
				display: table-cell;
			}
		}

		&:nth-last-child(4) {
			display: none;

			@include media-breakpoint-up(lg) {
				display: table-cell;
			}
		}

		&:nth-last-child(5) {
			display: none;

			@include media-breakpoint-up(xl) {
				display: table-cell;
			}
		}

		&:nth-last-child(5) + th {
			display: table-cell;
		}
	}

	th > .card {
		max-width: 254px;
	}

	tbody th {
		width: 270px;
	}

	td {
		min-width: 215px;
		width: 270px;
		vertical-align: bottom;

		&:last-child {
			width: auto;
		}

		&:nth-child(5) {
			width: 270px;
		}
	}

	tbody td > div {
		@include media-breakpoint-up(lg) {
			display: none;
		}
	}

	td:nth-child(-n + 3) > div,
	td:last-child > div {
		display: block;

		@include media-breakpoint-up(md) {
			display: none;
		}
	}

	td:nth-last-child(n + 3) > div,
	td:nth-last-child(4) > div,
	td:nth-last-child(4) + td > div,
	td:nth-child(5) > div {
		display: block;

		@include media-breakpoint-up(lg) {
			display: none;
		}
	}

	td:nth-last-child(4) > div {
		display: block;

		@include media-breakpoint-up(xl) {
			display: none;
		}
	}
}
