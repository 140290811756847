@import '../../node_modules/bootstrap/scss/functions';
@import '../../bootstrap/variables.scss';
@import "../../node_modules/bootstrap/scss/mixins";

.section {
	padding-top: map_get($spacers, 7);
	padding-bottom: map_get($spacers, 7);

	@include media-breakpoint-up(lg) {
		padding-top: map_get($spacers, 8);
		padding-bottom: map_get($spacers, 8);
	}

	p {
		margin-bottom: map_get($spacers, 4);
	}

	.container,
	.col-lg-6 {
		> h2 {
			margin-bottom: map_get($spacers, 5);
		}

		> h2 + p {
			margin-bottom: map_get($spacers, 6);
		}

		> p:first-child {
			margin-bottom: map_get($spacers, 2);
		}
	}

	.ratio-1x1 {
		@include media-breakpoint-up(lg) {
			--bs-aspect-ratio: calc(4 / 3 * 100%); // 4x3
		}

		@include media-breakpoint-up(xl) {
			--bs-aspect-ratio: 100%; // 1x1
		}
	}
}
