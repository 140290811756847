@import '../../node_modules/bootstrap/scss/functions';
@import '../../bootstrap/variables.scss';
@import "../../node_modules/bootstrap/scss/mixins";

.features-list {
	margin-bottom: 0;

	@include media-breakpoint-up(md) {
		margin-top: calc(map_get($spacers, 4) * -1);
	}

	&-cols-2 {
		@include media-breakpoint-up(md) {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			height: 256px;
		}
	}

	&-item {
		background-size: auto 28px!important;
		padding-left: map_get($spacers, 7);
		padding-right: map_get($spacers, 2);
		margin-top: map_get($spacers, 4);
		line-height: 1.25;
	}
}
