@import '../node_modules/bootstrap/scss/functions';

// Bootstrap default variable overrides
@import './variables.scss';

@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/root";

$utilities: map-merge(
	$utilities,
	(
		"border-start-dotted": (
			property: border-left-style,
			class: border-start-dotted,
			values: (
				null: dotted,
				0: 0
			)
		),

		"border-top-dotted": (
			property: border-top-style,
			class: border-top-dotted,
			values: (
				null: dotted,
				0: 0
			)
		),

		"border-bottom-dotted": (
			property: border-bottom-style,
			class: border-bottom-dotted,
			values: (
				null: dotted,
				0: 0
			)
		),

		"border-dotted": (
			property: border-style,
			class: border-dotted,
			values: (
				null: dotted,
				0: 0
			)
		),

		"min-width": (
			property: min-width,
			class: min-w,
			values: (0: 0)
		),
	)
);

$form-validation-states: (
  "invalid": (
    "color": $form-feedback-invalid-color,
    "icon": $form-feedback-icon-invalid
  )
);

@import '../node_modules/bootstrap/scss/reboot';
@import '../node_modules/bootstrap/scss/type';
@import '../node_modules/bootstrap/scss/images';
@import '../node_modules/bootstrap/scss/containers';
@import '../node_modules/bootstrap/scss/grid';
@import '../node_modules/bootstrap/scss/tables';
@import '../node_modules/bootstrap/scss/forms';
@import '../node_modules/bootstrap/scss/buttons';
@import '../node_modules/bootstrap/scss/transitions';
@import '../node_modules/bootstrap/scss/dropdown';
@import '../node_modules/bootstrap/scss/button-group';
@import '../node_modules/bootstrap/scss/nav';
@import '../node_modules/bootstrap/scss/navbar';
@import '../node_modules/bootstrap/scss/card';
@import '../node_modules/bootstrap/scss/accordion';
@import '../node_modules/bootstrap/scss/breadcrumb';
@import '../node_modules/bootstrap/scss/pagination';
//@import '../node_modules/bootstrap/scss/badge';
//@import '../node_modules/bootstrap/scss/alert';
//@import '../node_modules/bootstrap/scss/progress';
@import '../node_modules/bootstrap/scss/list-group';
@import '../node_modules/bootstrap/scss/close';
//@import '../node_modules/bootstrap/scss/toasts';
@import '../node_modules/bootstrap/scss/modal';
@import '../node_modules/bootstrap/scss/tooltip';
@import '../node_modules/bootstrap/scss/popover';
//@import '../node_modules/bootstrap/scss/carousel';
//@import '../node_modules/bootstrap/scss/spinners';
//@import '../node_modules/bootstrap/scss/offcanvas';
//@import '../node_modules/bootstrap/scss/placeholders';

@import '../node_modules/bootstrap/scss/helpers';
@import '../node_modules/bootstrap/scss/utilities/api';
