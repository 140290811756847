@import '../../node_modules/bootstrap/scss/functions';
@import '../../bootstrap/variables.scss';
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

.property-stretched-link {
	position: static!important;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		padding-top: 65%;

		@include media-breakpoint-up(lg) {
			@include make-col(5);
			bottom: 0;
			padding-top: 0;
		}
		@include media-breakpoint-up(xl) {
			@include make-col(6);
		}
	}
}
