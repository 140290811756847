@import '../../bootstrap/variables.scss';

.icon-compare {
	--bs-btn-disabled-bg: #7E8697;
	--bs-btn-disabled-border-color: #7E8697;
	--bs-btn-disabled-opacity: 1;
}

.btn-primary {
	color: $white;

	&:hover,
	&:active,
	&:focus {
		background-color: darken( $primary, 15% );
		color: $white;
		border-color: darken( $primary, 15% );
	}
}

.btn-info {
	color: $white;

	&:hover,
	&:active,
	&:focus {
		background-color: darken( $grey, 15% );
		color: $white;
		border-color: darken( $grey, 15% );
	}
}

.btn-icon {
	padding: 18px;
}

.btn-icon-lg {
	padding: 22px;
}
