@import "../node_modules/bootstrap/scss/functions";
@import "../bootstrap/variables.scss";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

main {
	//min-height: 50vh;
	min-width: 350px;
	position: relative;
	z-index: 900;
}

.cms-ready main > .container {
	min-height: 35vh;
}

.header + .no-hero {
	&::before {
		content: "";
		background: $dark;
		width: 100%;
		height: $header-sm-height;
		display: block;

		@include media-breakpoint-up(xl) {
			height: $header-xl-height;
		}
	}
}

.grecaptcha-badge {
    visibility: hidden;
}

.relative {
	position: relative;
}

.page-404 {
	background-color: $blue;

	@include media-breakpoint-up(lg) {
		background-image: url("../assets/bg-404.jpeg");
		background-size: cover;
		background-position-y: -60px;
		color: $blue-dark;
	}

	.container {
		@include media-breakpoint-up(xl) {
			min-height: 700px !important;
		}

		.aside {
			@include media-breakpoint-up(sm) {
				width: 400px;
			}
		}

		ul {
			padding-left: 1.5rem;
		}
	}
}

.register-plugin {
	flex: initial;
	width: 730px;
}
