@import '../../node_modules/bootstrap/scss/functions';
@import '../../bootstrap/variables.scss';
@import "../../node_modules/bootstrap/scss/variables";

.tns-ovh {
	overflow: hidden;
}

.tns-inner {
	margin-right: -30px!important;
}

.tns-visually-hidden {
	position: absolute; left: -10000em;
}

.tns-controls {
	text-align: center;
	padding-top: 32px;

	button {
		display: inline-block;
		width: 48px;
		height: 48px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: 50% 50%;
		background-color: transparent;
		border: 0;
		margin: 0 32px;
		text-indent: -999rem;
	}

	button[data-controls="prev"] {
		background-image: escape-svg(url('data:image/svg+xml,<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M30 36.28c-.34 0-.7-.08-1-.26L11.02 25.74c-.96-.54-1.3-1.78-.74-2.74.18-.3.44-.56.74-.74L29 12c.96-.54 2.18-.2 2.72.74.18.3.26.64.26 1V34.3c0 1.1-.9 2-2 2l.02-.02Zm0-22.56L12.02 24 30 34.28V13.72Z" fill="#fff"/><path d="M24 48C10.76 48 0 37.24 0 24S10.76 0 24 0s24 10.76 24 24-10.76 24-24 24Zm0-46C11.86 2 2 11.86 2 24s9.86 22 22 22 22-9.86 22-22S36.12 2 24 2Z" fill="#fff"/></svg>'));
	}

	button[data-controls="next"] {
		background-image: escape-svg(url('data:image/svg+xml,<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 11.72c.34 0 .7.08 1 .26l17.98 10.28c.96.54 1.3 1.78.74 2.74-.18.3-.44.56-.74.74L19 36c-.96.54-2.18.2-2.72-.74-.18-.3-.26-.64-.26-1V13.7c0-1.1.9-2 2-2l-.02.02Zm0 22.56L35.98 24 18 13.72v20.56Z" fill="#fff"/><path d="M24 0c13.24 0 24 10.76 24 24S37.24 48 24 48 0 37.24 0 24 10.76 0 24 0Zm0 46c12.14 0 22-9.86 22-22S36.14 2 24 2 2 11.86 2 24s9.88 22 22 22Z" fill="#fff"/></svg>'));
	}
}

.tns-controls-secondary {
	.tns-controls {
		button[data-controls="prev"] {
			background-image: escape-svg(url('data:image/svg+xml,<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M30 36.28c-.34 0-.7-.08-1-.26L11.02 25.74c-.96-.54-1.3-1.78-.74-2.74.18-.3.44-.56.74-.74L29 12c.96-.54 2.18-.2 2.72.74.18.3.26.64.26 1V34.3c0 1.1-.9 2-2 2l.02-.02Zm0-22.56L12.02 24 30 34.28V13.72Z" fill="#{$secondary}"/><path d="M24 48C10.76 48 0 37.24 0 24S10.76 0 24 0s24 10.76 24 24-10.76 24-24 24Zm0-46C11.86 2 2 11.86 2 24s9.86 22 22 22 22-9.86 22-22S36.12 2 24 2Z" fill="#{$secondary}"/></svg>'));
		}

		button[data-controls="next"] {
			background-image: escape-svg(url('data:image/svg+xml,<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 11.72c.34 0 .7.08 1 .26l17.98 10.28c.96.54 1.3 1.78.74 2.74-.18.3-.44.56-.74.74L19 36c-.96.54-2.18.2-2.72-.74-.18-.3-.26-.64-.26-1V13.7c0-1.1.9-2 2-2l-.02.02Zm0 22.56L35.98 24 18 13.72v20.56Z" fill="#{$secondary}"/><path d="M24 0c13.24 0 24 10.76 24 24S37.24 48 24 48 0 37.24 0 24 10.76 0 24 0Zm0 46c12.14 0 22-9.86 22-22S36.14 2 24 2 2 11.86 2 24s9.88 22 22 22Z" fill="#{$secondary}"/></svg>'));
		}
	}
}

.tns-controls-grey {
	.tns-controls {
		button[data-controls="prev"] {
			background-image: escape-svg(url('data:image/svg+xml,<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M30 36.28c-.34 0-.7-.08-1-.26L11.02 25.74c-.96-.54-1.3-1.78-.74-2.74.18-.3.44-.56.74-.74L29 12c.96-.54 2.18-.2 2.72.74.18.3.26.64.26 1V34.3c0 1.1-.9 2-2 2l.02-.02Zm0-22.56L12.02 24 30 34.28V13.72Z" fill="#{$grey}"/><path d="M24 48C10.76 48 0 37.24 0 24S10.76 0 24 0s24 10.76 24 24-10.76 24-24 24Zm0-46C11.86 2 2 11.86 2 24s9.86 22 22 22 22-9.86 22-22S36.12 2 24 2Z" fill="#{$grey}"/></svg>'));
		}

		button[data-controls="next"] {
			background-image: escape-svg(url('data:image/svg+xml,<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 11.72c.34 0 .7.08 1 .26l17.98 10.28c.96.54 1.3 1.78.74 2.74-.18.3-.44.56-.74.74L19 36c-.96.54-2.18.2-2.72-.74-.18-.3-.26-.64-.26-1V13.7c0-1.1.9-2 2-2l-.02.02Zm0 22.56L35.98 24 18 13.72v20.56Z" fill="#{$grey}"/><path d="M24 0c13.24 0 24 10.76 24 24S37.24 48 24 48 0 37.24 0 24 10.76 0 24 0Zm0 46c12.14 0 22-9.86 22-22S36.14 2 24 2 2 11.86 2 24s9.88 22 22 22Z" fill="#{$grey}"/></svg>'));
		}
	}
}
