@import "../../node_modules/bootstrap/scss/functions";
@import "../../bootstrap/variables.scss";
@import "../../node_modules/bootstrap/scss/mixins";

.map {
	height: 60vh;
	width: 100%;
	z-index: 0;
	position: relative;

	@include media-breakpoint-up(md) {
		height: 450px;
	}

	@include media-breakpoint-up(xl) {
		height: calc(100vh - 92px);
		position: sticky;
		top: 92px;
	}
}

.map-fixed {
	@include media-breakpoint-up(xl) {
		height: 450px;
		position: static;
	}
}

.map-popover {
	--bs-popover-max-width: 360px;
	--bs-popover-body-padding-x: 0;
	--bs-popover-body-padding-y: 0;
	--bs-popover-body-color: $white;
	--bs-popover-bg: $blue;
}

.map-popover.bs-popover-top > .popover-arrow::after,
.map-popover.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
	border-top-color: $blue;
}

.map-popover.bs-popover-end > .popover-arrow::after,
.map-popover.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
	border-right-color: $blue;
}

.map-popover.bs-popover-start > .popover-arrow::after,
.map-popover.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
	border-left-color: $blue;
}

.map-popover.bs-popover-bottom > .popover-arrow::after,
.map-popover.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
	border-bottom-color: $blue;
}

.map-body {
	padding: 14px 16px 16px 16px;
}

.map-img {
	width: 104px;
	object-fit: cover;
	z-index: 1001;
}

a.map-title {
	font-size: 14px;
	font-weight: 700;
	padding: 0;
	margin: 0;
	color: inherit;
	text-decoration: none;

	&:hover {
		color: var(--bs-link-hover-color);
	}
}

.map-text {
	font-size: 12px;
	padding-bottom: 0;
	margin-bottom: 0;
}
