@import '../../node_modules/bootstrap/scss/functions';
@import '../../bootstrap/variables.scss';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';


.top-navbar {
	a {
		text-decoration: none;
	}

	.navbar-brand {
		margin-left: calc($grid-gutter-width / 2);
		max-width: 55%;

		img {
			width: 210px;
			height: auto;
			max-width: 100%;

			@include media-breakpoint-up(sm) {
				width: 235px;
			}
		}
	}

	.navbar-toggler {
		border: 0;

		&:focus,
		&:active {
			box-shadow: none;
		}
	}

	.nav-item {
		border-top: 1px solid darken($grey, 20%);
	}

	.nav-link {
		padding-left: calc($grid-gutter-width / 2);
		padding-right: calc($grid-gutter-width / 2);
	}

	.btn-primary {
		width: 100%;
		text-align: left;
		padding-left: calc($grid-gutter-width / 2);
		padding-right: calc($grid-gutter-width / 2);
	}

	.dropdown-menu-user {
		display: block;
	}

	.btn-user {
		display: none;
	}

	.navbar-toggler-icon-user {
		display: inline-block;
		width: 1.85em;
		height: 1.5em;
		vertical-align: middle;
	}

	button.dropdown-toggle {
		padding: 0;
		margin: 0;
		height: 30px;
		width: 30px;
		border: 0;
		position: absolute;
		right: calc($grid-gutter-width / 2);
		top: .5rem;
		background-image: escape-svg(url('data:image/svg+xml,<svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.314 1.657 6.657 7.314 1 1.657" stroke="#fff" stroke-width="2"/></svg>'));
		background-position: 50% 50%;
		background-repeat: no-repeat;

		&:after {
			display: none;
		}
	}

	@include media-breakpoint-up(xl) {
		padding: 0 $grid-gutter-width;

		.navbar-collapse {
			flex-grow: 0;
		}
		button.dropdown-toggle {
			display: none;
		}

		.btn-user {
			display: inline-block;
			padding-left: .5rem;
			padding-right: .5rem;

			&:focus,
			&:active,
			&:active:focus {
				box-shadow: none;
			}
		}

		.dropdown-menu-user {
			display: none;
		}
		.dropdown-menu-user.show {
			display: block;
		}

		.navbar-nav.dropdown:hover .dropdown-menu,
		.nav-item.dropdown:hover .dropdown-menu {
			display: block;
		}

		.navbar-nav.dropdown:hover .dropdown-menu {
			margin-top: 2px;
		}

		.navbar-nav.dropdown:hover .dropdown-menu-user {
			right: 0;
			margin-top: 2px;
		}

		.nav-item {
			border-top: 0;
		}

		.nav-item,
		.dropdown {
			margin: 0 .75rem;
			padding: 1.25rem 0;
		}

		.dropdown-menu {
			top: 100%;
		}

		.nav-link {
			padding: 2px;
			display: flex;
			flex-direction: column;
			text-align: center;
			position: relative;
		}

		.nav-link.active {
			&:before {
				content: '';
				border-top: 5px solid $primary;
				width: 100%;
				position: absolute;
				left: 0;
				top: -20px
			}
		}

		.navbar-nav:hover {
			.nav-link.active:before {
				border: 0;
			}

			.nav-link.active:hover,
			.nav-link:hover {
				text-decoration: none;

				&:before {
					content: '';
					border-top: 5px solid $primary;
					width: 100%;
					position: absolute;
					left: 0;
					top: -20px;
				}
			}
		}

	}
}

.cms-toolbar-expanded .top-navbar {
	margin-top: 46px;
}
