@import '../../node_modules/bootstrap/scss/functions';
@import '../../bootstrap/variables.scss';
@import "../../node_modules/bootstrap/scss/mixins";

.hero {
	padding-top: 90px;
	min-width: 350px;
	position: relative;
	z-index: 100;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		min-height: 520px;
		background: $secondary url('./HeroBG_Img_Standard_v2.jpg') no-repeat 0% 50%;
		background-size: cover;
	}

	&-initial:before {
		min-height: initial;
	}

	&-tall:before {
		min-height: 590px;
	}

	&-home:before {
		background-image: url('./HeroBG_Img_Home_v2.jpg');
	}

	&-news-articles:before {
		background-image: url('./HeroBG_Img_NewsArticles.jpg');
	}

	&-product-page:before {
		background-image: url('./HeroBG_Img_ProductPage.jpg');
	}

	&-residence:before {
		background-image: url('./HeroBG_Img_Residence.jpg');
	}

	&-selection:before {
		background-image: url('./HeroBG_Img_Selection.jpg');
	}

	.container {
		position: relative;
		z-index: 900;
		min-height: 430px;

		&_collapsed {
			min-height: 0!important;
		}

		&:empty {
			display: none;
		}
	}



	@include media-breakpoint-up(md) {
		padding-top: 160px;

		.container {
			min-height: 360px;
		}

		&_collapsed {
			min-height: 0!important;
		}
	}
}

.cms-ready .hero .container {
	min-height: 0;
}

.cms-ready .hero .container .cms-placeholder {
	min-height: 360px;

	&:empty {
		min-height: 0;
	}
}
