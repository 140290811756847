@import '../../node_modules/bootstrap/scss/functions';
@import '../../bootstrap/variables.scss';
@import "../../node_modules/bootstrap/scss/mixins";

.property-details-img {
	@include media-breakpoint-up(lg) {
		overflow: hidden;
		position: relative;

		img {
			height: 100%;
			width: auto!important;
			max-width: 300%!important;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			cursor: pointer;
		}
	}
}
