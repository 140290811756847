@import '../../node_modules/bootstrap/scss/functions';
@import '../../bootstrap/variables.scss';
@import "../../node_modules/bootstrap/scss/mixins";

.contact-form {
	position: sticky;
	min-height: 616px;
	top: 104px;

	&-wrapper {
		width: 350px;
		max-width: 350px;
		right: 0px;
    	position: absolute;
	}

	textarea {
		resize: none;
	}

	.form-control {
		padding: 0.445rem 0.95rem;
	}

	.small-text {
		font-size: 0.8rem;
	}
}

.block-height {
	min-height: 616px;
}
