.not-available {
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
	filter: grayscale(100%);
	position: relative;
/*
	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		background: rgba($color: #000, $alpha: 0.3);
		padding-top: calc(400/700 * 100%);
	}*/
}
