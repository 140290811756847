@import '../../node_modules/bootstrap/scss/functions';
@import '../../bootstrap/variables.scss';
@import "../../node_modules/bootstrap/scss/variables";

.icon-user {
	background-image: escape-svg(url('data:image/svg+xml,<svg width="39" height="22" viewBox="0 0 39 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M37.3136 9.65685L31.6567 15.3137L25.9999 9.65685" stroke="white" stroke-width="2"/><path d="M9.72427 11.6097C6.52194 11.6097 3.91943 9.00717 3.91943 5.80484C3.91943 2.6025 6.52194 0 9.72427 0C12.9266 0 15.5291 2.6025 15.5291 5.80484C15.5291 9.00717 12.9266 11.6097 9.72427 11.6097ZM9.72427 0.967473C7.05405 0.967473 4.88691 3.13461 4.88691 5.80484C4.88691 8.47506 7.05405 10.6422 9.72427 10.6422C12.3945 10.6422 14.5616 8.47506 14.5616 5.80484C14.5616 3.13461 12.3945 0.967473 9.72427 0.967473Z" fill="white"/><path d="M18.4314 21.7681H1.01694C0.746047 21.7681 0.533203 21.5553 0.533203 21.2844C0.533203 16.2148 4.65464 12.0934 9.72419 12.0934C14.7938 12.0934 18.9152 16.2148 18.9152 21.2844C18.9152 21.5553 18.7023 21.7681 18.4314 21.7681ZM1.51035 20.8006H17.938C17.6865 16.4857 14.0972 13.0609 9.72419 13.0609C5.35122 13.0609 1.76189 16.4857 1.51035 20.8006Z" fill="white"/></svg>'));
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.icon-arrow-down {
	background-image: escape-svg(url('data:image/svg+xml,<svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.314 1.657 7.657 7.314 2 1.657" stroke="#fff" stroke-width="3"/></svg>'));
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.icon-arrow-up {
	background-image: escape-svg(url('data:image/svg+xml,<svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 8.657 7.657 3l5.657 5.657" stroke="#fff" stroke-width="3"/></svg>'));
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.icon-arrow-left {
	background-image: escape-svg(url('data:image/svg+xml,<svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m7.667 1-6 6 6 6" stroke="#{$secondary}" stroke-width="2"/></svg>'));
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.icon-arrow-right {
	background-image: escape-svg(url('data:image/svg+xml,<svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m1.333 13 6-6-6-6" stroke="#{$secondary}" stroke-width="2"/></svg>'));
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.icon-back {
	background-image: escape-svg(url('data:image/svg+xml,<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M.293 7.293a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.414-1.414L2.414 8l5.657-5.657A1 1 0 0 0 6.657.93L.293 7.293ZM17 7H1v2h16V7Z" fill="#fff"/> </svg>'));
	background-position: map_get($spacers, 3) 50%;
	background-repeat: no-repeat;
	padding-left: map_get($spacers, 6);
}
