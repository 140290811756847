@import '../../node_modules/bootstrap/scss/functions';
@import '../../bootstrap/variables.scss';
@import "../../node_modules/bootstrap/scss/mixins";

.accordion-button {
	font-size: inherit;
	font-weight: inherit;
}

.accordion-item {
	margin-top: map_get($spacers, 5);

	&:first-child {
		margin-top: 0;
	}
}
