@import '../../bootstrap/variables.scss';

.cookie-bar {
	position: fixed;
	display: block;
	box-sizing: border-box;

	padding: 1em;
	background: $blue;
	color: $white;

	width: 100dvw;
	bottom: 0;

	text-align: center;
	line-height: 25px;
	z-index: 1000;
  }
